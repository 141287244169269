export type TestimonialType = {
  category?: string
  testimonial: string
  assets?: { video?: string; img?: string }
  name: string
  rating: number
  image?: string
}
export const testimonials: TestimonialType[] = [
  {
    category: "",
    testimonial:
      "I'm happy to take part in this Olympiad, exploring its relevance for our educators and the potential impact on teaching practices.",
    name: "Pasquina Rowena",
    rating: 5,
  },
  {
    category: "",
    testimonial:
      "It was good experience overall as there were some theories I wasn't familiar with so it was challenging. I would recommend to take part in it and assess.",
    name: "Emina Miagul Murtic",
    rating: 4.75,
  },
  {
    category: "video",
    rating: 4.25,
    name: "Aarti Shekhawat",
    testimonial: "Questions, Recognition",
    assets: {
      img: "/assets/ito/reviews/review-post/aarti-shekhawat.webp",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Aarti%20Shekhawat_3.mp4",
    },
  },
  {
    category: "",
    testimonial:
      "International Teachers' Olympiad was an amazing experience. I thoroughly enjoyed it as it enriched my teaching journey.",
    name: "Zinnia Holt",
    rating: 4.75,
  },
  {
    category: "",
    testimonial:
      "Congratulations to the team! The whole process was efficiently organised. Special thanks to Mr Rishabh Khanna for thinking about teachers and their professional growth. He has given a new dimension to this profession.",
    name: "Rozy ",
    rating: 5,
  },
  {
    category: "",
    testimonial:
      "It is an optimistic  initiative by Suraasa. I really feel International Teachers' Olympiad  would become big in enhancing teacher's appraisal and reputation",
    name: "Molly Mathew",
    rating: 4.25,
  },
  {
    category: "video",
    rating: 5,
    name: "Nene Ibezim",
    testimonial: "Growth, Report",
    assets: {
      img: "/assets/ito/reviews/review-post/nene-ibezim.webp",
      video: "https://assets.suraasa.com/ito/2023/testimonial-videos/Nene.mp4",
    },
  },
  {
    category: "",
    testimonial:
      "The overall journey of  the Olympiad was highly educational and valuable. Many thanks to the entire team for this providing this opportunity to the teachers.",
    name: "Zebia Riaz",
    rating: 4.5,
  },
  {
    category: "",
    testimonial:
      "A very good experience that has enriched my teaching journey. It's been truly valuable. Thank you so much for the opportunity guys!",
    name: "Blessy T",
    rating: 5,
  },
  {
    category: "",
    testimonial:
      "Initially, I thought it was fake as the'start' icon was clicked everything went blank . As things got resolved,  I got the questionnaire which went on smoothly as mentioned.  And today I am really thankful to the team for  their support and giving me this novel experience.",
    name: "Liny Alexander",
    rating: 4.75,
  },
  {
    category: "",
    testimonial:
      "International Teachers' Olympiad is a great opportunity by Suraasa, uniting teachers globally for growth and excellence. I am glad I could be a part of it.",
    name: "Fiola Haulice  D'Mello",
    rating: 4.5,
  },
  {
    category: "video",
    rating: 5,
    name: "Riju Singh",
    testimonial: "Recognition, Authenticity",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Riju%20Singh.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Riju%20Singh.mp4",
    },
  },
  {
    category: "video",
    rating: 4.25,
    name: "Kalpita K",
    testimonial: "Growth ",
    assets: {
      img: "/assets/ito/reviews/review-post/kalpita-k.webp",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Kalpita%20K.mp4",
    },
  },
  {
    category: "",
    testimonial:
      "I am fresher but I really liked it and the questions also very useful to me,I liked the way of questions they asked and I wil participate in upcoming years also thankyou to CEO of surassa for conducting such exam.",
    name: "Sumayyaarifa",
    rating: 4.25,
  },
  {
    category: "video",
    rating: 4.25,
    name: "Sailaja",
    testimonial: "Questions, Skills",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Sailaja.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Sailaja_1.mp4",
    },
  },
  {
    category: "",
    testimonial:
      "Great opportunity for teachers to have an introspection. It will definitely help us to elevate ourselves in the teaching profession. Thanks for the sincere support and guidance.",
    name: "Mary Joseph",
    rating: 4.5,
  },
  {
    category: "",
    testimonial:
      "Participating in the Olympiad was truly amazing. It provided a valuable experience to exchange teaching perspectives. I thoroughly enjoyed the process and found it enriching to share our teaching insights.",
    name: "Liza James",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.5,
    name: "Suvidha Singh",
    testimonial: "Questions, Authenticity",
    assets: {
      img: "/assets/ito/reviews/review-post/suvidha-singh.webp",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Suvidha%20Singh.mp4",
    },
  },
  {
    category: "",
    testimonial:
      "It was a very good experience that provided me with valuable insights into teaching .I appreciated the chance to reflect on my own teaching approach . Thank you so much",
    name: "Naphisabet Lyngdoh",
    rating: 4.25,
  },
  {
    category: "",
    testimonial:
      "Thank you for the great experienc.  initially I did have difficulty in getting connected but due to perseverance I kept trying n then finally got connected. I am happy as long as I was able to participate and look forward to more of these events. Thank you",
    name: "Angela Maria Franco",
    rating: 5,
  },
  {
    category: "",
    testimonial:
      "I love the way each individual participant is being carried along before and even during the Olympiad. All the content & the videos posted was explanatory enough.Kudos to Suraasa",
    name: "Olawunmi Afolake Kehinde",
    rating: 4.5,
  },
  {
    category: "video",
    rating: 4.5,
    name: "Shilpa Hiwale",
    testimonial: "Skills, Questions",
    assets: {
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Shilpa%20Hiwale_1.mp4",
    },
  },
  {
    category: "",
    testimonial:
      "To be a teacher means to be a student for lifetime.. We need to keep upgrading our technical skills.. This is an amazing platform to help a teacher impart quality teaching ",
    name: "Keneil -ü  Suokhrie",
    rating: 4.5,
  },
  {
    category: "video",
    rating: 5,
    name: "Silpa Nandakumar",
    testimonial: "Recognition, Growth",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Shilpa%20Nandkumar.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Silpa%20Nandakumar_1.mp4",
    },
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "All the questions were quite relevant to what I teach in the school. I even had the time to review my answers in the end. The portal was quite easy to use. Thank you Suraasa for providing me the opportunity to grow and learn with you.",
    name: "Snehal Christian",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.25,
    name: "Sumit Mandwani",
    testimonial: "Regonition, Growth",
    assets: {
      img: "/assets/ito/reviews/review-post/sumit-mandwani.webp",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Sumit%20Mandwani.mp4",
    },
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "In the assessment, highly innovative questions were asked. It was a really new experience. My mind got boosted after attending the Olympiad. Thank you so much.",
    name: "Bavaji Syed",
    rating: 5,
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "The questions asked in the Olympiad were extremely relevant to the teaching & learning process. It was a learning experience with self analysis. Looking forward for more such opportunities.",
    name: "Neelam Gupta",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.75,
    name: "Tabitha Grace",
    testimonial: "Questions",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Tabitha.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Tabitha%20Grace.mp4",
    },
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "It was really an awesome experience for me as a Teacher. The questions were set in an International Standard. Thank you team for providing teachers such a wonderful opportunity ",
    name: "Radhika A",
    rating: 4.75,
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "It was a challenging experience. The questions were thought provoking. They were most relevant to the current scenario of teaching and learning programmes.",
    name: "Christopher Vidyanand",
    rating: 4.75,
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "It was so nice and questions were so relevant to our daily classes. You face such situations in your school & the Olympiad questions were so useful in that regard. I could literally experience myself in those situations through these questions. Grateful to be a part on ITO.",
    name: "Kottapu Divya",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.75,
    name: "Shilpi Agarwal",
    testimonial: "Recognition, Growth, Skills",
    assets: {
      img: "/assets/ito/reviews/review-post/shilpi-agarwal.webp",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Shilpi%20Agarwal.mp4",
    },
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "I used critical thinking and analysis to answer most of the questions. Some questions were also addressed using my practical teaching experience. I enjoyed the process of going through the questionnaire and providing answers. Overall, the experience was quite enjoyable.",
    name: "Deepika Karnani",
    rating: 4.25,
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "International Teachers' Olympiad 2023 was a great experience. The questions asked in the assessment were quite relevant for teaching practice. It has helped me as a Teacher.",
    name: "Vinisha Nalhe",
    rating: 5,
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "As a newcomer, I genuinely appreciated the experience. The questions were very helpful for someone like me who is just starting out. I found the question style to be engaging . I plan to take part in future editions as well. I'd like to extend my gratitude to the CEO for organising the Olympiad.",
    name: "Sumayyaarifa",
    rating: 4.25,
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "The questions in the Olympiad were quite imaginative and aligned well with Bloom's Taxonomy. After I gave it, I felt happy and satisfied with the experience.",
    name: "Niharika Singh",
    rating: 5,
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "Dealing with those profound, higher-order thinking questions was truly an enlightening experience. I'm grateful to have had the opportunity to engage with the comprehensive essence of my passion through the Olympiad.",
    name: "Ibanylla Warjri",
    rating: 4.5,
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "The questions were tricky, but they can be helpful for our classroom teaching. Thank you team Suraasa for providing me such opportunity to gauge my knowledge.",
    name: "Sudha Lawson",
    rating: 4.75,
  },
  {
    category: "video",
    rating: 5,
    assets: {
      img: "/assets/ito/reviews/review-post/zubaida-sabeen.webp",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Zubaida%20Sabeen_2.mp4",
    },
    testimonial: "recognition",
    name: "Zubaida Sabeen",
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "Participating in the Teacher's Olympiad was a fantastic experience. The questions directly related to my teaching and provided motivation. ",
    name: "Sarojini Sajwan",
    rating: 4.5,
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "The experience was quite enlightening. Some questions were challenging, as they weren't covered in our B.Ed. course. However, the rest were manageable. ",
    name: "Zainab Munir",
    rating: 5,
  },
  {
    category: "Questions & Pattern",
    testimonial:
      "Participating was truly wonderful. The questions were relevant and easy to understand. The Olympiad covered all significant aspects of teaching and learning activities.",
    name: "Geetalaxmi Sathyanathan",
    rating: 5,
  },
  {
    category: "video",
    rating: 5,
    name: "Tejaswini Anand Rao",
    testimonial: "Recognition, Skills",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Tejaswani%20Anand%20Rao.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Tejaswini%20Anand%20Rao.mp4",
    },
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "This initiative is truly commendable as it provides teaching faculty with the opportunity to conduct real-time analyses of the daily challenges encountered while working with students and colleagues in school. It aids in addressing various issues with a broader perspective.",
    name: "Komal Pandya",
    rating: 5,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "The experience was wonderful, and it highlighted areas where I need to improve in my teaching. It allowed me to enhance my teaching techniques and apply them in my classes.",
    name: "Shikha Gautam",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.5,
    name: "Anubhuti Shukla",
    testimonial: "Questions",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Anubhuti%20Shukla.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Anubhuti%20Shukla_1.mp4",
    },
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "The concept of ITO promises to provide teachers with valuable knowledge and experiences for the future. It adds a new dimension to their teaching careers. Best wishes to the Surasa team for their efforts in fostering interest in teaching as a career choice for the new generation. ",
    name: "Dhanashree Satish Gadade",
    rating: 5,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "This experience is highly beneficial for our career advancement and personal growth. The situations presented are highly relevant to our current circumstances. It's a wonderful opportunity to put our goals into action.",
    name: "M. Vijayalakshmi",
    rating: 5,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "I'm grateful for this platform that you've provided for teachers like us. It helps us identify our weaknesses and work towards enhancing the overall classroom experience.",
    name: "Dr. Gurdeep",
    rating: 4.5,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "After attempting the ITO , I am overwhelmed. I want to express my gratitude to the creator for making me a part of the best community---Suraasa, where I can enhance my skills and contribute to the idea that learning continues throughout life, not just through graduation or PhD.",
    name: "Fouzia Begum",
    rating: 5,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "Participating in this Olympiad has significantly enhanced my teaching skills and professional growth. I am really grateful to Suraasa for the opportunity.",
    name: "S.Punitha",
    rating: 5,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "The International Teachers' Olympiad will bring out the best results in your teaching career and imprint an enthusiastic method to move ahead in this profession.",
    name: "Rita Basu",
    rating: 4.25,
  },
  {
    category: "video",
    rating: 4.75,
    name: "Anuradha Srinivasan",
    testimonial: "Recognition",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Anuradha.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Anuradha%20Srinivasan.mp4",
    },
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "It's been a truly wonderful experience for me, especially since I'm restarting my teaching career after an 8-year break. I feel fortunate to have a platform like this to refine and enhance my knowledge. grateful for such a valuable opportunity.",
    name: "Neeru Bala",
    rating: 4.75,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "The questions were extremely analytical, offering me the opportunity to contemplate current situations and anticipate forthcoming challenges. They provided insights into teaching thoughtfully and handling situations with care, while also identifying areas for improvement as a facilitator.",
    name: "Sumana Nayak",
    rating: 4.25,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "ITO is an engaging &  informative tool that helps us understand how to shape our teaching strategies for a more creative & effective approach to achieving learning objectives. It also teaches us how to incorporate various technologies to enhance our teaching methods.",
    name: "Narendra Solanki",
    rating: 5,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "The examination has proven to be incredibly beneficial for my learning and for enhancing my teaching profession. It felt like time well spent on my phone, learning something valuable. I kindly request that similar examinations be held frequently for teachers to self-assess and improve. ",
    name: "A.Karthigaiselvi",
    rating: 5,
  },
  {
    category: "video",
    rating: 5,
    name: "Deepa",
    testimonial: "Recognition, Growth",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Deepa.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Deepa_1.mp4",
    },
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "I strongly recommend that these tests be conducted biannually instead of annually. This frequency would greatly aid in identifying strengths and weaknesses while providing more opportunities for growth.",
    name: "Rashida Shoeb",
    rating: 5,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "Continuous assessments like these are crucial for personal growth, especially when we find ourselves getting stagnated. They provide the necessary momentum for development.",
    name: "Monami Chakraborty",
    rating: 4.5,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "It was excellent. I learned a lot and feel more confident in my teaching career . Suraasa's team has been instrumental in clarifying my teaching ambitions. Thank you for everything.",
    name: "Aashi Tiwari",
    rating: 4.5,
  },
  {
    category: "Impact on Teaching",
    testimonial:
      "The Olympiad questions offer valuable insights into my teaching strategies, experiences, and classroom management. Kudos for creating such a beneficial initiative",
    name: "Aisha Abubakar Mohammed",
    rating: 5,
  },
  {
    category: "Support & Help",
    testimonial:
      "I commend the team for their dedicated support in equipping and updating teachers to meet the challenges of future generations. A heartfelt thank you to everyone involved.",
    name: "Chakravarthy Nethala",
    rating: 4.5,
  },
  {
    category: "video",
    rating: 4.25,
    name: "Deepti Razdan",
    testimonial: "Growth ",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Deepti.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Deepti%20Razdan_1.mp4",
    },
  },
  {
    category: "Support & Help",
    testimonial:
      "Many thanks to the Suraasa Team for arranging such fantastic Olympiad. I was given the help when I asked for it whenever I needed it. Once again, thank you for your quick response and support.",
    name: "Vaishali Prakaash",
    rating: 4.75,
  },
  {
    category: "Support & Help",
    testimonial:
      "I'm grateful to the entire team for their exceptional support. After the challenges I encountered, I had almost lost hope of taking the exam. Thanks to your guidance, it became possible",
    name: "Swapna Kannan",
    rating: 4.75,
  },
  {
    category: "Support & Help",
    testimonial:
      " Though I initially encountered some issues with the questions, the technical support swiftly resolved them after communication. Thanks again to the entire team, and I wish you all the best.",
    name: "Bindu Sreenath",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.75,
    name: "Raksha Sadashiva",
    testimonial: "Recognition",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Raksha.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Raksha%20Sadashiva_1.mp4",
    },
  },
  {
    category: "Support & Help",
    testimonial:
      "I had an awesome experience at the International Teachers' Olympiad 2022! My hearfelt thanks to the entire team for the tremendous support and their dedicated help.",
    name: "Chaitali Banerjee",
    rating: 5,
  },
  {
    category: "Support & Help",
    testimonial:
      "The support you provided during my technical issue was truly amazing. I was worried that I might have lost the amount I paid, but your assistance came at the perfect moment, just when I needed it. ",
    name: "Aparna H",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.5,
    name: "Fatema Harnesswala",
    testimonial: "Recognition",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Fatema.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Fatema%20Harnesswala_1.mp4",
    },
  },
  {
    category: "Support & Help",
    testimonial:
      "I encountered a technical issue while starting the Olympiad test, but your help portal swiftly resolved it. I want to express special appreciation to your team for the support!",
    name: "Pragati Tendulkar",
    rating: 4.75,
  },
  {
    category: "Support & Help",
    testimonial:
      "Thank you, Team Suraasa. Your support has been truly helpful. I've never taken a test like this before. I was uncertain , but I felt good after attempting it. ",
    name: "Poonam Rani",
    rating: 4.5,
  },
  {
    category: "Support & Help",
    testimonial:
      "The support I received during the initial login stage of my assessment was commendable. Many thanks to the technical team and the Suraasa team as a whole.",
    name: "Mrs Fatima Inamdar",
    rating: 5,
  },
  {
    category: "Support & Help",
    testimonial:
      "The portal proved to be incredibly useful. A big shoutout to the support team for their swift resolution of my issues. I'm excited to stay connected with this platform moving forward. ",
    name: "Dhritipurna Das",
    rating: 4.75,
  },
  {
    category: "video",
    rating: 4.75,
    name: "Geeta Varshneya",
    testimonial: "Questions, Authenticity",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Geeta.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Geeta%20Varshneya_1.mp4",
    },
  },
  {
    category: "Support & Help",
    testimonial:
      "Personally, I'm highly satisfied with the International Teachers' Olympiad, including the supportive sessions, and the overall follow-up throughout the academic year.",
    name: "Suhas Jadhav",
    rating: 5,
  },
  {
    category: "Support & Help",
    testimonial:
      "Suraasa's motivation and recognition have propelled me towards my dream career in teaching. I'm pursuing it with great enthusiasm, aiming to achieve success with the guidance and support offered by the Team. Will be participating in ITO 2023.",
    name: "Farhana Begam A.W.",
    rating: 5,
  },
  {
    category: "Support & Help",
    testimonial:
      "I wholeheartedly appreciate the unwavering efforts of the Suraasa Team. They've been exceptional in keeping participants informed, providing technical support, and handling the assessment and feedback process.",
    name: "Manorama Subramanian",
    rating: 5,
  },
  {
    category: "Support & Help",
    testimonial:
      "I initially faced difficulties logging into the portal when I sat down to give the Olympiad. However, I truly commend the prompt response and assistance in resolving the issue.",
    name: "Kalaiyarasi Arunkumar",
    rating: 4,
  },
  {
    category: "Support & Help",
    testimonial:
      "Thank you for providing a wonderful experience. Although I faced initial connectivity issues, I persisted and managed to connect after about 15 minutes with the help of support team. I'm content that I could participate and eagerly anticipate more such events.",
    name: "Angela Maria Franco",
    rating: 5,
  },
  {
    category: "video",
    rating: 5,
    name: "Kabir Bhatia",
    testimonial: "Questions",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Kabir.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Kabir%20Bhatia.mp4",
    },
  },
  {
    category: "Support & Help",
    testimonial:
      "The team's support during ITO was exceptional. When my portal went blank, their quick response was reassuring. I've recommended Suraasa to friends and family. Looking forward to more teachers joining next time as well.",
    name: "Brigette Cuntapay",
    rating: 4.75,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "ITO 2022 was a success that recognised the efforts of educators worldwide & also sparked conversations & collaborations to further improve education on an international scale. It showcased the power of education in shaping the world's future &  left a lasting positive impact on everyone.",
    name: "Abishek Sharma",
    rating: 5,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "My overall impression of the event was exceptional.  I gained a lot from the experience. Organizing a Teacher Olympiad to acknowledge and honor educators' efforts is truly commendable. This idea holds the potential to build a sense of community and advance professional development among teachers.",
    name: "Usha Alagiriswamy",
    rating: 4,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "Participating in such an international experience also brings the opportunity for teacher recognition and portfolio development. I'm eagerly looking forward to another year of enriching experience",
    name: "Meenakshi Kumbeswaran",
    rating: 4.5,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "Participating in the Olympiad was an overwhelming experience for me. It was especially joyful to find myself among the top 30% of teachers globally. I focused on improving my target skill and now I'm excited to participate in the upcoming Olympiad once again.",
    name: "Swati Nijhawan",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.5,
    name: "Michelle Fernandez",
    testimonial: "Report, Growth",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Michelle%20Fernandez.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Michelle%20Fernandez_2.mp4",
    },
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "International Teachers' Olympiad was really amazing, one must definitely participate in it. It boost confidence in oneself. As I was in Top 30% teachers' globally, I felt really confident about myself. Looking forward to be part of it in 2023 as well.",
    name: "Farheen Tai",
    rating: 4.75,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "International Teachers' Olympiad gives evidence that you are a qualified teacher and significantly enhancing your market reputation. It also boosts your confidence.",
    name: "Anubha Singh",
    rating: 4.5,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "The International Teachers Olympiad exam provides platform for educators to showcase their knowledge and teaching skills, fostering global learning and collaboration. Highly recommended for passionate educators seeking growth & recognition.",
    name: "Tayyaba Shaikh",
    rating: 5,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "The Olympiad gave me  a chance, for the first time ever, to determine my core skills and competencies. I could see and relate myself on the Global platform",
    name: "Archana Singh",
    rating: 4.75,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "One of the most inspiring aspects of the International Teachers' Olympiad was the emphasis on celebrating teaching excellence. The event honored exceptional educators for their outstanding contributions to education, motivating all participants to strive for greatness in their respective roles.",
    name: "Kumud Sharma",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.75,
    name: "Mukta",
    testimonial: "Authenticity, Report",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Mukta.png",
      video: "https://assets.suraasa.com/ito/2023/testimonial-videos/Mukta.mp4",
    },
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "ITO was a transformative, it breathed new life into my teaching career. I will forever cherish it. It refined my teaching potential & instilled a sense of confidence. I now embrace innovation, understanding that failure is an essential part of the learning process.",
    name: "Jasmin Hepziba",
    rating: 5,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "An opportunity for me to know where I stand in the world. It was a joy to discover myself and the platform celebrated teachers. A joyful experience with lots of choices for growth. I loved it",
    name: "Lavanya Suresh",
    rating: 4.25,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "It was a great learning experience and offered tremendous exposure to various aspects of teaching and learning. The process involved a significant amount of unlearning and rethinking of different concepts. Overall, it has significantly boosted my confidence as a teacher.",
    name: "Sushma Viswakumar",
    rating: 4.75,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "The Olympiad made me realise that the struggles & hurdles I've faced in teaching were worth it. It affirmed that I'm skilled and that teaching is my calling. I'm sincerely thankful to Surasaa for organising this  for teachers. I believe every teacher would have found it to be a fantastic experience.",
    name: "Raksha Sadashiva",
    rating: 5,
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "I often downplay my achievements, but participating in this Olympiad has boosted my confidence. Seeing my friends share my achievement on their profiles feels really good. It's making me more ambitious and driving me to aim for even greater things.",
    name: "Silpa Nandakumar",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.25,
    name: "Pallavi Malaviya",
    testimonial: "Skills",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Pallavi.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Pallavi%20Malaviya_02.mp4",
    },
  },
  {
    category: "Rewards & Recognition",
    testimonial:
      "Every individual deserves appreciation. I'd like to applaud the Suraasa Olympiad team for uniting teachers from around the world on a single platform and making each one feel valued. Keep up the great work!",
    name: "Zainab Nathani",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The post olympiad experience insightful, engaging, and actionable. The trainers' approach to presenting, extensive knowledge of the topic,the style of questioning, the emphasis on reflection, the interactive Q&A session, and the overall encouragement were great!",
    name: "Khushi Rauf",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "It was a very good experience, and I am satisfied with the efforts. Thank you, Team Suraasa. The activities were effective in addressing pedagogical challenges and integrating CPT (Continual Professional Training).",
    name: "Sangeeta Vashistha",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The new methodology of education presented in the monthly growth plans was very impressive. I appreciate Team Suraasa for taking this significant step.",
    name: "Sonia Bhagria",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The masterclasses effectively addressed various classroom issues, leaving no aspect untouched. I'm pleased to have attended such an informative sessions.",
    name: "Prasanti Shadangi",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The way topics were explained and the innovative and interesting approach to the learning is captivating. I found it quite engaging and informative, learning a lot from Suraasa.",
    name: "Kavita",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.5,
    name: "Paravthy S Raj",
    testimonial: "Questions",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Paravthy%20S%20Raj.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Paravthy%20S%20Raj_1.mp4",
    },
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The pace and tone of the masterclass session were commendable. The trainer was well-prepared and maintained a good mood throughout. I found it to be fantastic and well-conducted. ",
    name: "Celine Lazarus",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The strategies discussed in the personalised report and monthly growth plans were valuable. Dear Team Suraasa, you're putting in your best effort; keep up the excellent work!",
    name: "Swapna",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The content was substantial, and the delivery was of top-notch quality. This was the first event of its kind for me, and it exceeded expectations. Kudos to the team!",
    name: "omojolelekan@yahoo.com",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.75,
    name: "Prabhat Kumar",
    testimonial: "Report, Questions",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Prabhat.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Prabhat%20Kumar.mp4",
    },
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The resources and its content that was provided post the Olympiad was the highlight for me. It's already amazing, and I can't think of anything that has not been covered!",
    name: "Juanita",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The flow of the Masterclasses was seamless. The content was relatable and addressed many of the challenges I face during differential instruction. I eagerly anticipate our next ITO; it's interactive, thought-provoking, and just perfect",
    name: "Anupama Neogi",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The report was incredibly impressive, presented in easily understandable language. The masterclasses were both meaningful and helpful, significantly enhancing my knowledge. They concentrated on pedagogy and technology, which is precisely what teachers need in today's time.",
    name: "Zeba",
    rating: 5,
  },
  {
    category: "video",
    rating: 5,
    name: "Pramitha ",
    testimonial: "Recognition",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Pramitha.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Pramitha_1.mp4",
    },
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The masterclass helped me realise that certain teaching methods are genuinely child-centric and comprehensible for students, as opposed to rote learning. The techniques discussed are applicable to various levels, including Kindergarten, primary, and beyond.",
    name: "Liny Alexander",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "Everything about the entire experience met my expectations--the Olympiad, the report, the plans and the masterclasses. Expecially the Live Masterclass sessions were perfect.",
    name: "Maxim Digbehi",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      " ITO gave me an insight of where I really stand as n aspiring teacher. The results were a bit surprising for me but the reports showed me my strengths and weaknesses. I got an idea how I can improve. I would definitely recommend all the teachers to participate in ITO in future.",
    name: "Iram Khan",
    rating: 4.25,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The personalised report provided is much needed for refining our teaching skills. While knowing what to teach is already clear, the real challenge lies in how to teach effectively. I'm grateful that the Olympiad has encouraged a forward-looking approach",
    name: "Keneile -ü Suokhrie",
    rating: 4.5,
  },
  {
    category: "video",
    rating: 4.25,
    name: "Priyanka Mahajan",
    testimonial: "Questions, Growth",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Priyanka%20mahajan.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Priyanka%20Mahajan.mp4",
    },
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The Olympiad covered a comprehensive list of teaching aspects, which were later explained in the reports. It was an overwhelming experience, reminiscent of school days. Taking it felt nostalgic. Any teacher considering it will undoubtedly gain from it. ",
    name: "Salma Rashid",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "ITO has been highly beneficial for my skill development. The domain reports accurately pinpointed areas where improvement is needed. Eagerly anticipating the upcoming ITO.",
    name: "Mohammed Tousif Mulla",
    rating: 5,
  },
  {
    category: "Report, Monthly Plans & Masterclasses",
    testimonial:
      "The personalised report for each teacher is the best part, helping us in our teaching careers. Thanks to Team Suraasa for this wonderful initiative for teachers.",
    name: "Preeti Thakur",
    rating: 5,
  },
  {
    category: "video",
    rating: 4.5,
    name: "Rachel Alexander",
    testimonial: "Recognition, Report",
    assets: {
      img: "https://assets.suraasa.com/ito/2023/testimonial-videos/thumbnails/Rachel.png",
      video:
        "https://assets.suraasa.com/ito/2023/testimonial-videos/Rachel%20Alexander.mp4",
    },
  },
]
