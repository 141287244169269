export default {
  snow: "#FFFFFF",
  moonlight: "#EFEFF7",
  midnight: "#050124",
  darkGreen: "#07332A",
  salmon: {
    100: "#FBE7E6",
    200: "#F7CECE",
    300: "#F4B6B6",
    400: "#F19F9D",
    500: "#FF7879",
    600: "#EB483F",
    700: "#D72E20",
    800: "#961D13",
    900: "#560C07",
  },
  peach: {
    100: "#FCF2E8",
    200: "#FAE5D1",
    300: "#F7D7BA",
    400: "#F4CBA4",
    500: "#FBB77E",
    600: "#EB964D",
    700: "#D6732D",
    800: "#95511D",
    900: "#552E0E",
  },
  mint: {
    100: "#E4F9EB",
    200: "#CAF4D7",
    300: "#B1EFC4",
    400: "#9AE9B1",
    500: "#54E690",
    600: "#69DB7B",
    700: "#50A85E",
    800: "#377642",
    900: "#1E4325",
  },
  iris: {
    100: "#E1EBFC",
    200: "#C3D6FB",
    300: "#A5C2F8",
    400: "#88AFF7",
    500: "#5295FC",
    600: "#346DF2",
    700: "#2252C6",
    800: "#16398B",
    900: "#0A204F",
  },
  lavender: {
    100: "#EEE8FD",
    200: "#DDD1FC",
    300: "#CCBAFA",
    400: "#BCA3F9",
    500: "#AC83FF",
    600: "#743FF5",
    700: "#4909E7",
    800: "#3304A2",
    900: "#1C025D",
  },
  bubblegum: {
    100: "#FCECF9",
    200: "#F9D9F2",
    300: "#F5C6EC",
    400: "#F3B3E7",
    500: "#FE96E3",
    600: "#EA59CA",
    700: "#E532B5",
    800: "#A0207F",
    900: "#5B0E48",
  },
}
