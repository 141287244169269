import { useEffect } from "react"

type Options = {
  ignorePadding: boolean
}

export const useScrollShiftOffset = (enabled: boolean, options?: Options) => {
  useEffect(() => {
    if (enabled) {
      // Accounting for content shift due to the scrollbar
      if (!options?.ignorePadding) {
        document.body.style.paddingInlineEnd = `${
          window.innerWidth - document.documentElement.clientWidth
        }px`
      }
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "initial"
      if (!options?.ignorePadding) {
        document.body.style.paddingInlineEnd = ""
      }
    }

    return () => {
      document.body.style.overflow = "initial"
      if (!options?.ignorePadding) {
        document.body.style.paddingInlineEnd = ""
      }
    }
  }, [enabled])
}
