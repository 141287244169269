import React from "react"

import { Avatar } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import { createUseStyles } from "react-jss"

import { Play, Star } from "iconoir-react"

import customTheme from "utils/customTheme"

import SwitzerTypography from "../SwitzerTypography"

import { TestimonialType } from "./testimonials"

type Props = {
  className?: string
  data: TestimonialType
  id: number
  isAboutReport?: boolean
  isRatingAndReviewPage?: boolean
  onPlay?: (url: string) => void
}

const useStyles = createUseStyles(theme => ({
  fullName: {
    textWrap: "nowrap",
  },
  testimonialFontFamily: {
    ...theme.typography.body,
    fontFamily: "Switzer Regular",
    "& span": {
      font: "inherit",
    },
  },
  one: {
    "& span": {
      color: customTheme.lavender[600],
      fontWeight: 500,
    },
  },
  two: {
    "& span": {
      color: customTheme.bubblegum[600],
      fontWeight: 500,
    },
  },
  three: {
    "& span": {
      color: customTheme.iris[600],
      fontWeight: 500,
    },
  },
  four: {},
}))

const avatarColors = {
  0: "one",
  1: "two",
  2: "three",
  3: "four",
} as const

function createMarkup(data: string) {
  return {
    __html: data,
  }
}

const ReviewCard = ({
  className,
  data,
  id,
  isAboutReport,
  onPlay,
  isRatingAndReviewPage,
}: Props) => {
  const classes = useStyles()
  const colorList = isAboutReport ? 3 : 4
  const color = avatarColors[(id % colorList) as keyof typeof avatarColors]
  return (
    <div
      className={clsx(
        className,
        "p-2 shadow-[0px_6px_15px_0px_#0000000D] rounded-xl bg-white shrink-0"
      )}
    >
      <div className="grid grid-cols-[70%_auto] grid-rows-auto gap-1 items-center justify-between mb-1.5">
        <div className="flex items-center">
          <div>
            {data.image ? (
              <Image
                alt={data.name}
                className="object-cover w-5 h-5 bg-white !block rounded-full border-2 border-solid border-white"
                height={40}
                src={data.image}
                width={40}
              />
            ) : (
              <Avatar color={color} name={data.name} />
            )}
          </div>
          <SwitzerTypography
            className={clsx(
              classes.fullName,
              "overflow-hidden ms-1 text-ellipsis"
            )}
            fontFamily="Switzer Bold"
            variant="strong"
          >
            {data.name}
          </SwitzerTypography>
        </div>
        <div className="px-1 py-0.5 flex gap-0.5 items-center rounded bg-warning-50">
          <SwitzerTypography fontFamily="Switzer Medium">
            {data.rating}
          </SwitzerTypography>
          <Star className="w-2 h-2 text-warning-400" fill="#EEC239" />
        </div>
      </div>
      <div>
        {data.category === "video" ? (
          <button
            className={clsx("relative w-full overflow-hidden rounded-lg", {
              "h-[280px]": isRatingAndReviewPage,
              "h-[180px]": !isRatingAndReviewPage,
            })}
            onClick={() => {
              if (onPlay && data.assets?.video) {
                onPlay(data.assets?.video)
              }
            }}
          >
            {data.assets?.img && (
              <Image
                alt={data.name}
                className="object-cover w-full h-full bg-white !block"
                height={100}
                src={data.assets?.img}
                width={100}
              />
            )}

            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-black/50">
              <div className="bg-black/25 rounded-full w-[36px] h-[36px] flex items-center justify-center">
                <Play className="text-white" fill="#ffffff" />
              </div>
            </div>
          </button>
        ) : (
          <div
            className={clsx(classes.testimonialFontFamily, classes[color])}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={createMarkup(data.testimonial)}
            style={{ whiteSpace: "break-spaces" }}
          />
        )}
      </div>
    </div>
  )
}
export default ReviewCard
