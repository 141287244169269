import React from "react"

import { IconButton } from "@suraasa/placebo-ui-legacy"

import { Xmark } from "iconoir-react"

import SlidingSheet from "components/shared/SlidingSheet"

const VideoSheet = ({
  videoURL,
  onClose,
}: {
  videoURL?: string
  onClose: () => void
}) => (
  <SlidingSheet
    className="relative overflow-hidden !bg-black/80"
    open={Boolean(videoURL)}
    fullScreen
    unmountOnExit
    onClose={onClose}
  >
    <div className="relative h-full">
      <IconButton
        className="absolute z-[2] top-3 right-3"
        color="white"
        size="md"
        onClick={onClose}
      >
        <Xmark fontSize={30} />
      </IconButton>

      <div className="absolute text-white -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 max-h-[80vh] sm:h-full h-fit">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video className="w-full h-full bg-white" autoPlay controls>
          <source src={videoURL} type="video/mp4" />
        </video>
      </div>
    </div>
  </SlidingSheet>
)

export default VideoSheet
