import React from "react"

import { Button, ButtonComponentProps } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  gradientButton: {
    borderRadius: "999px",
    borderBottom: "solid 4px transparent",
    background:
      "linear-gradient(90deg, #EB964D, #EB483F, #EA59CA, #743FF5, #346DF2, #69DB7B,#EB964D)",
    boxShadow: "2px 1000px 1px #050124 inset",
    animation: "$gradient 12s linear infinite",
    backgroundSize: "200% 200%",
    "&:focus": {
      outline: "none !important",
    },
    "&:active": {
      boxShadow: "2px 1000px 1px #35324F inset",
    },
    "&:hover": {
      animationPlayState: "paused",
    },
  },
  "@keyframes gradient": {
    "0%": {
      backgroundPosition: "0px 0",
    },
    "100%": {
      backgroundPosition: "100em 0",
    },
  },
})

const GradientButton = ({
  children,
  className,
  ...otherProps
}: ButtonComponentProps<any> & { className?: string }) => {
  const classes = useStyles()

  return (
    <Button className={clsx(classes.gradientButton, className)} {...otherProps}>
      {children}
    </Button>
  )
}

export default GradientButton
