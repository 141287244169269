import React, { useEffect, useRef, useState } from "react"

import Glide from "@glidejs/glide"
import {
  Button,
  Container,
  Theme,
  Typography,
} from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Link from "next/link"
import { createUseStyles, useTheme } from "react-jss"

import {
  ArrowLeftCircle,
  ArrowRightCircle,
  NavArrowRight,
  Star,
} from "iconoir-react"

import NoSSR from "components/shared/NoSSR"
import { RatingAndReviewId } from "utils/constants"

import VideoSheet from "../../reviews/VideoSheet"
import SwitzerTypography from "../SwitzerTypography"

import ProgressCard from "./ProgressCard"
import ReviewCard from "./ReviewCard"
import { testimonials } from "./testimonials"

type Props = {
  fromPaymentMotivation?: boolean
  spacing?: string
  isReviewPage?: boolean
}

const useStyles = createUseStyles(() => ({
  container: {
    backgroundColor: "#ffffff",
    backgroundImage:
      "radial-gradient(at 0% 0%, rgba(254, 150, 227, 0.1) 0px, transparent 45%), radial-gradient(at 45% -10%, rgba(243, 179, 231, 0.15) 0px, transparent 55%), radial-gradient(at 75% 40%, rgba(82, 149, 252, 0.15) 0px, transparent 56%), radial-gradient(at 26% 75%, rgba(154, 233, 177, 0.15) 0px, transparent 45%), radial-gradient(at 71% 73%, rgba(105, 219, 123, 0.15) 0px, transparent 45%)",
  },
  reviewPageContainer: {
    backgroundColor: "#ffffff",
    backgroundImage:
      "radial-gradient(at 45% 100%, rgba(254, 150, 227, 0.2) 0px, transparent 45%), radial-gradient(at 45% 0%, rgba(243, 179, 231, 0.25) 0px, transparent 55%), radial-gradient(at 100% 0%, rgba(82, 149, 252, 0.25) 0px, transparent 45%), radial-gradient(at 100% 100%, rgba(82, 149, 252, 0.25) 0px, transparent 65%), radial-gradient(at 0% 85%, rgba(154, 233, 177, 0.25) 0px, transparent 45%), radial-gradient(at 0% 25%, rgba(105, 219, 123, 0.25) 0px, transparent 45%)",
  },
  halfStar: {
    clipPath: "polygon(0% 0%,50% 0%,50% 100%,0% 100%)",
  },
}))

const RatingAndReviews = ({
  spacing,
  fromPaymentMotivation = false,
  isReviewPage,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const reviewCard = useRef<HTMLDivElement | null>(null)
  const [videoURL, setVideoURL] = useState<string | undefined>()
  const [glide, setGlide] = useState<Glide>()
  const nextStep = () => {
    if (glide) {
      glide.go(">")
    }
  }

  const prevStep = () => {
    if (glide) {
      glide.go("<")
    }
  }

  useEffect(() => {
    const instance = new Glide(`#glide`, {
      type: "slider",
      perView: 3.1,
      rewind: true,
      focusAt: 0,
      bound: true,
      peek: { before: 0, after: 0 },
      breakpoints: {
        [theme.breakpoints.values.md]: {
          perView: 2.1,
        },
        [theme.breakpoints.values.sm]: {
          perView: 1,
        },
        [theme.breakpoints.values.xs]: {
          perView: 1,
        },
      },
    })

    if (reviewCard.current && !isReviewPage) {
      const mount = instance.mount()
      setGlide(mount)
    }

    return () => {
      if (glide) {
        glide.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewCard.current, isReviewPage])

  const onClose = () => {
    setVideoURL(undefined)
  }
  const onPlay = (url: string) => {
    setVideoURL(url)
  }

  return (
    <div className={spacing} id={RatingAndReviewId}>
      <Container
        className={clsx({
          "pb-3 sm:pb-4": !fromPaymentMotivation && !isReviewPage,
        })}
      >
        {!fromPaymentMotivation && !isReviewPage && (
          <SwitzerTypography
            className="mb-6"
            fontFamily="Switzer Bold"
            textAlign="center"
            variant="title1"
          >
            Ratings and Reviews
          </SwitzerTypography>
        )}
        <div
          className={clsx({
            [classes.container]: !fromPaymentMotivation && !isReviewPage,
            [classes.reviewPageContainer]: isReviewPage,
            "md:p-4 p-2 rounded-2xl shadow-[0px_6px_15px_0px_rgba(0,0,0,0.05)]":
              !fromPaymentMotivation,
          })}
        >
          <div className="flex flex-wrap items-center mb-2">
            <SwitzerTypography
              className="mr-3 w-fit"
              fontFamily="Switzer Bold"
              variant="largeTitle"
            >
              4.48
            </SwitzerTypography>
            <div>
              <div className="flex gap-0.5">
                <Star className="text-warning-400" fill="#EEC239" />
                <Star className="text-warning-400" fill="#EEC239" />
                <Star className="text-warning-400" fill="#EEC239" />
                <Star className="text-warning-400" fill="#EEC239" />
                <div className="relative">
                  <Star className="text-[#EFEFF7]" fill="#EFEFF7" />
                  <Star
                    className={clsx(
                      classes.halfStar,
                      "absolute top-0 left-0 text-warning-400"
                    )}
                    fill="#EEC239"
                  />
                </div>
              </div>
              <Typography color="onSurface.500" variant="smallBody">
                Based on 4,253+ ratings
              </Typography>
            </div>
          </div>
          {!fromPaymentMotivation && (
            <div
              className={clsx(
                "grid md:grid-cols-[auto_auto_auto_auto] md:grid-rows-[auto] sm:grid-cols-[auto_auto] sm:grid-rows-[auto_auto] grid-cols-[auto] grid-rows-[auto_auto_auto_auto] gap-3",
                {
                  "mb-6": !isReviewPage,
                }
              )}
            >
              <ProgressCard
                className="w-full"
                progress="w-full"
                stars="5.0"
                title="Relevance"
              />
              <ProgressCard
                className="w-full"
                progress="w-[85%]"
                stars="4.25"
                title="Accuracy"
              />
              <ProgressCard
                className="w-full"
                progress="w-[95%]"
                stars="4.75"
                title="Engagement"
              />
              <ProgressCard
                className="w-full"
                progress="w-[90%]"
                stars="4.5"
                title="Impact"
              />
            </div>
          )}
          <NoSSR>
            {!isReviewPage && (
              <div id="glide" ref={reviewCard}>
                <div
                  className="py-1 mb-2 overflow-hidden glide__track"
                  data-glide-el="track"
                >
                  <div className="flex glide__slides">
                    {testimonials.slice(0, 50).map((item, index) => (
                      <ReviewCard
                        className="min-h-[270px] h-full"
                        data={item}
                        id={index}
                        key={index}
                        onPlay={onPlay}
                      />
                    ))}
                  </div>
                  <div className="flex flex-wrap items-center justify-between gap-1 mt-2">
                    <div className="flex items-center" data-glide-el="controls">
                      <button
                        className="mr-1"
                        data-glide-dir="<"
                        onClick={prevStep}
                      >
                        <ArrowLeftCircle className="w-4 h-4" />
                      </button>
                      <button data-glide-dir=">" onClick={nextStep}>
                        <ArrowRightCircle className="w-4 h-4" />
                      </button>
                    </div>
                    {!fromPaymentMotivation && !isReviewPage && (
                      <Button
                        className="!block !rounded-full !text-ito-midnight !border-ito-midnight !pr-1"
                        component={Link}
                        endAdornment={<NavArrowRight />}
                        href="/international-teachers-olympiad/ratings-and-reviews?from=landing-page"
                        size="sm"
                        variant="outlined"
                      >
                        View All
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </NoSSR>
        </div>
      </Container>
      <VideoSheet videoURL={videoURL} onClose={onClose} />
    </div>
  )
}

export default RatingAndReviews
