import React, { PropsWithChildren } from "react"

import { Typography, TypographyProps } from "@suraasa/placebo-ui-legacy"

type Props = {
  fontFamily?: "Switzer Regular" | "Switzer Medium" | "Switzer Bold"
} & TypographyProps

const SwitzerTypography = ({
  fontFamily,
  children,
  ...props
}: PropsWithChildren<Props>) => (
  <Typography
    style={{
      fontFamily,
    }}
    {...props}
  >
    {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
    <>{children}</>
  </Typography>
)

export default SwitzerTypography
