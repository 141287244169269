export const edition2023Prices = {
  returnPercentage: {
    IN: "5000",
    AE: "5500",
    US: "4900",
  },
  referralDiscount: {
    IN: "50",
    AE: "",
    US: "",
  },
  registrationFee: {
    IN: "549",
    AE: "25",
    US: "9",
  },
  totalPrizePool: {
    IN: "30 Lakhs",
    AE: "150,000",
    US: "40,000",
  },
  skillAnalysisReport: { IN: "5,000", AE: "250", US: "100" },
  growthPlan: {
    IN: "9,000",
    AE: "450",
    US: "180",
  },
  certificate: {
    IN: "4,000",
    AE: "200",
    US: "50",
  },
  masterclasses: { IN: "10,000", AE: "500", US: "125" },
  totalRewards: {
    IN: "28,000",
    AE: "1,400",
    US: "455",
  },
  cashPrizeBronze: { IN: "10,000", AE: "500", US: "125" },
  cashPrizeSilver: { IN: "50,000", AE: "2,500", US: "610" },
  cashPrizeThird: { IN: "1 Lakh", AE: "5,000", US: "1,220" },
  cashPrizeSecond: { IN: "2 Lakhs", AE: "10,000", US: "2,430" },
  cashPrizeFirst: { IN: "3 Lakhs", AE: "15,000", US: "3,650" },
  ituScholarship: { IN: "1 Lakh", AE: "5,000", US: "1,220" },
  ituScholarshipTotal: { IN: "8 Crores", AE: "4 Million", US: "1 Million" },
} as const

export type Edition2023PriceCategories = keyof typeof edition2023Prices
