import { Country } from "api/resources/global/types"
import { Registration } from "api/resources/registrationForm/types"

import { Edition2022PriceCategores, edition2022Prices } from "./2022/constants"
import { Edition2023PriceCategories, edition2023Prices } from "./2023/constants"

export type OlympiadEditions = "2022" | "2023"

const currencyCode = {
  IN: {
    code: "INR",
    symbol: "₹",
  },
  AE: {
    code: "AED",
    symbol: "AED",
  },
  US: {
    code: "USD",
    symbol: "$",
  },
} as const

export type PriceCategories<T> = T extends "2022"
  ? Edition2022PriceCategores
  : T extends "2023"
  ? Edition2023PriceCategories
  : never

export const getITOPrices = <T extends OlympiadEditions>(
  edition: T,
  priceKey: PriceCategories<T>,
  countryCode?: string | null
) => {
  let price
  switch (edition) {
    case "2022":
      price = edition2022Prices[priceKey as PriceCategories<"2022">]
      break
    case "2023":
      price = edition2023Prices[priceKey as PriceCategories<"2023">]
      break
    default:
      throw new Error("ITO Edition not handled")
  }

  const defaultPrice = `${currencyCode.US.symbol}${price.US}`

  if (typeof window === "undefined") return defaultPrice

  if (!countryCode) {
    return defaultPrice
  }

  if (countryCode === "IN") {
    return `${currencyCode[countryCode].symbol}${price[countryCode]}`
  }

  if (countryCode === "AE") {
    return `${price[countryCode]} ${currencyCode[countryCode].symbol}`
  }

  return defaultPrice
}

export const getRegistrationStatus = (
  itoDetails: Registration | null | undefined
) => {
  if (!itoDetails) return "notStarted"

  const {
    registrationFeePaid,
    registeredUserDetail: details,
    olympiadSchool,
    state,
    city,
  } = itoDetails

  if (
    !registrationFeePaid ||
    !details ||
    !details.educationLevel ||
    !details.currentlyTeaching ||
    !details.dateOfBirth ||
    !state ||
    !city
  )
    return "pending"

  if (details.currentlyTeaching === "yes") {
    if (
      !details.teachingExperience ||
      !details.teachingLevel ||
      !olympiadSchool
    )
      return "pending"
  }

  return "completed"
}

export const getITOVideoSrc = (country: Country | null | undefined) => {
  const global =
    "https://assets.suraasa.com/ito/2023/video/ito-launch-GLOBAL.mp4"
  const india = "https://assets.suraasa.com/ito/2023/ito-video.mp4"
  const UAE = "https://assets.suraasa.com/ito/2023/video/ito-launch-UAE.mp4"
  const US = "https://assets.suraasa.com/ito/2023/video/ito-launch-US.mp4"

  switch (country?.isoCode) {
    case "IN":
      return india
    case "AE":
      return UAE
    case "US":
      return US
    default:
      return global
  }
}

export function combineArraysRandomly<T>(arr1: T[], arr2: T[]): T[] {
  const combinedArray = [...arr1, ...arr2]

  for (let i = combinedArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[combinedArray[i], combinedArray[j]] = [combinedArray[j], combinedArray[i]]
  }

  return combinedArray
}
