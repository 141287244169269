import React from "react"

import { Typography } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"

type Props = {
  className?: string
  progress: string
  title: string
  stars: string
}

const ProgressCard = ({ className, progress, title, stars }: Props) => (
  <div className={className}>
    <div className="flex justify-between mb-1.5">
      <Typography variant="strong">{title}</Typography>&nbsp;
      <Typography variant="strong">{stars}</Typography>
    </div>
    <div className="relative w-full h-1 bg-white rounded-full">
      <div
        className={clsx(
          progress,
          "h-full bg-ito-mint-600 absolute top-0 left-0 rounded-full"
        )}
      />
    </div>
  </div>
)

export default ProgressCard
