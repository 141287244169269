export const edition2022Prices = {
  registrationFee: {
    IN: "549",
    AE: "25",
    US: "9",
  },
  totalPrizePool: {
    IN: "30,00,000",
    AE: "150,000",
    US: "37,500",
  },
  growthPlan: {
    IN: "5,000",
    AE: "250",
    US: "100",
  },
  certificate: {
    IN: "2,000",
    AE: "100",
    US: "25",
  },
  masterclasses: { IN: "10,000", AE: "500", US: "125" },
  freeCourse: {
    IN: "7,500",
    AE: "370",
    US: "100",
  },
  totalRewards: {
    IN: "24,500",
    AE: "1220",
    US: "350",
  },
  totalTeachersPay: {
    IN: "12,500",
    AE: "620",
    US: "200",
  },
  cashPrizeBronze: { IN: "10,000", AE: "500", US: "125" },
  cashPrizeSilver: { IN: "50,000", AE: "2,500", US: "625" },
  cashPrizeThird: { IN: "1,00,000", AE: "5,000", US: "1,250" },
  cashPrizeSecond: { IN: "2,00,000", AE: "10,000", US: "2,500" },
  cashPrizeFirst: { IN: "3,00,000", AE: "15,000", US: "3,750" },
} as const

export type Edition2022PriceCategores = keyof typeof edition2022Prices
