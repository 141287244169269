type Props = {
  href: string
  /**
   * Should be passed as `true` when the href is relative to the current origin
   */
  isRelative?: boolean
  render: (href: string) => JSX.Element
}

const PreserveQueryParams = ({ isRelative = false, href, render }: Props) => {
  const isServer = typeof window === "undefined"
  const search = new URLSearchParams(isServer ? "" : window.location.search)

  const origin = isServer
    ? process.env.NEXT_PUBLIC_SURAASA_PLATFORM_URL
    : window.location.origin

  const url = new URL(href, origin)

  search.forEach((value, key) => {
    url.searchParams.append(key, value)
  })

  // Don't need this right now
  // if (!isServer && !url.searchParams.has("source_url")) {
  //   url.searchParams.append("source_url", document.referrer)
  // }

  const fullUrl = isRelative
    ? `${href}?${url.searchParams.toString()}`
    : url.toString()

  return render(fullUrl)
}

export default PreserveQueryParams
