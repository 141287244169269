import React, { useEffect, useRef } from "react"

import { Theme, Typography, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { createUseStyles, useTheme } from "react-jss"

import NoSSR from "components/shared/NoSSR"
import { GA, TrackingMeta } from "utils/googleAnalytics"
import useDetectCountry from "utils/hooks/useDetectCountry"

type Props = {
  style?: Record<string, any>
  hideOnXs?: boolean
  source?: TrackingMeta["source"]
}

const useStyles = createUseStyles(theme => ({
  icon: {
    textDecoration: "none",
    position: "fixed",
    bottom: 20,
    right: 16,
    backgroundColor: "#46C655",
    padding: theme.spacing(1),
    borderRadius: 50,
    transition: "width 250ms ease, opacity 250ms ease",
    width: 45,
    zIndex: 11,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .whatsapp-help-text": {
      opacity: "0",
      display: "none",
    },

    "&.whatsapp-btn-expand": {
      width: 100,

      "& .whatsapp-help-text": {
        marginLeft: 8,
        opacity: "1",
        display: "block",
      },
    },
  },
  img: {
    height: 30,
    width: 30,

    marginBottom: -2,
    marginRight: -1,
  },
}))

const ChatOnWhatsAppFAB = ({ style, hideOnXs, source }: Props) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const country = useDetectCountry()

  const classes = useStyles()
  const ref = useRef<HTMLAnchorElement | null>(null)

  useEffect(() => {
    if (!ref.current || (hideOnXs && isXs)) return

    let timer: ReturnType<typeof setTimeout>

    const expand = function () {
      ref.current?.classList.add("whatsapp-btn-expand")
    }

    const onScroll = function () {
      ref.current?.classList.remove("whatsapp-btn-expand")
      clearTimeout(timer)
      timer = setTimeout(expand, 500)
    }

    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideOnXs, isXs, ref.current])

  if (country?.isoCode !== "IN" || (hideOnXs && isXs)) return null

  return (
    <NoSSR>
      <a
        {...GA.trackElement("whatsapp-help-fab-btn", {
          feature: "ito",
          purpose: "contact-us",
          source,
        })}
        className={clsx(classes.icon, "whatsapp-btn-expand")}
        href="https://api.whatsapp.com/send/?phone=919986702707&text=Talk%20to%20Support"
        ref={ref}
        rel="noreferrer"
        style={style}
        target="_blank"
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          alt="whatsapp"
          className={classes.img}
          src="/assets/icons/whatsapp-icon.svg"
        />
        <span className="whatsapp-help-text">
          <Typography color="common.white.500" variant="button">
            Help
          </Typography>
        </span>
      </a>
    </NoSSR>
  )
}

export default ChatOnWhatsAppFAB
